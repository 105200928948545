const GithubIcon: React.FC<{}> = (props) => {
  return (
    <div className = "w-5 group-hover:scale-105">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 34 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9185 1.0807C12.263 1.05869 8.71846 2.37712 5.91884 4.80023C3.11921 7.22334 1.24703 10.5931 0.637038 14.3071C0.0270505 18.021 0.719033 21.8368 2.58927 25.0723C4.4595 28.3078 7.38602 30.7519 10.8455 31.9677C11.6095 32.1198 11.9009 31.6304 11.9036 31.2127C11.9064 30.795 11.899 29.5838 11.9026 28.2597C7.61256 29.1905 6.72595 26.3525 6.72595 26.3525C6.03936 24.5089 5.03279 24.0189 5.03279 24.0189C3.64192 23.0272 5.14367 23.0554 5.14367 23.0554C6.69068 23.1785 7.49409 24.7076 7.49409 24.7076C8.84999 27.1415 11.0857 26.4546 11.9655 26.0506C12.11 25.0242 12.5149 24.328 12.9586 23.9342C9.53933 23.5125 5.95297 22.128 5.99285 16.0488C5.98198 14.472 6.55982 12.9511 7.60679 11.8006C7.45132 11.4002 6.93407 9.7837 7.78479 7.59814C7.78479 7.59814 9.08037 7.18102 12.0094 9.25212C14.538 8.55755 17.2009 8.57501 19.7201 9.3027C22.6736 7.27018 23.961 7.70424 23.961 7.70424C24.7855 9.89553 24.2471 11.5051 24.0864 11.9087C25.1215 13.073 25.6805 14.6041 25.647 16.183C25.607 22.2753 21.9953 23.5942 18.5657 23.9631C19.1133 24.4607 19.5991 25.428 19.5893 26.9098C19.5754 29.0325 19.5464 30.74 19.5429 31.2628C19.5402 31.6858 19.8149 32.1815 20.5961 32.0316C24.0717 30.8612 27.0303 28.4553 28.9428 25.2441C30.8553 22.0328 31.5969 18.2258 31.035 14.5039C30.4731 10.7819 28.6444 7.3878 25.8759 4.92858C23.1074 2.46936 19.5796 1.10543 15.9236 1.08073L15.9185 1.0807Z"
          fill="#0e7490"
        />
      </svg>
    </div>
  );
};
export default GithubIcon;
